.Profile {
    position: absolute;
    margin-left: 24%;
    top: 0;
    margin-top: 7rem;
    color: white;
}

.logoutButton {
    cursor: pointer;
    padding: 0.4rem;
    border-radius: 7px;
    border: none;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
    transition: box-shadow 0.3s;
}

.logoutButton:hover {
    box-shadow: inset 1px 1px 1px 1px white;
}

.profileButtons {
    display: flex;
    gap: 1rem;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem;
}

.editForm,
.sourceCodes {
    max-height: 0; 
    visibility: hidden; 
    transform: translateY(-50px);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease, max-height 0.5s ease, visibility 0.5s ease;
    overflow: hidden;
    position: absolute;
}

.editForm.show,
.sourceCodes.show {
    max-height: 500px;
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
}


.editForm > input {
    padding: 0.4rem;
    border-radius: 17px;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
}

.editForm > button {
    padding: 0.4rem;
    border-radius: 17px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
    border: solid 1px white;
}

.sourceCode {
    font-size: xx-large;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 14px;
}

@media (max-width: 1028px) {
    .Profile {
        margin-left: 10%;
    }
}



