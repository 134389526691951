.Login {
    position: absolute;
    margin-left: 24%;
    top: 0;
    margin-top: 7rem;
}

form {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: rgba(0, 0, 0, 0.704);
    padding: 4rem;
    width: 40vw;
}

form > label {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

form > label > input {
    padding: 7px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
    border: none;
    border-bottom: solid 1px white;
    font-size:large;
    letter-spacing: 1px;
}

.submitButton {
    padding: 0.4rem;
    cursor: pointer;
}

form > span {
    cursor: pointer;
}

@media (max-width: 1028px) {
    .Login {
        margin-left: 10%;
    }

}