.Projects {
    position: absolute;
    margin-left: 24%;
    top: 0;
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
}

.workContainer {
    width: 100%;
}
  
.workWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.workItems {
    display: flex;
    gap: 20px;
}
  
.cards__item {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: all 0.3s ease;
}
  
.cards__item__link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
}
  
.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
}
  
.cards__item__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
    border-radius: 10px;
}
  
.cards__item__info {
    padding: 15px;
}
  
.cards__item__text {
    font-weight: 600;
    font-size: larger;
}
  
.cards__item:hover {
    transform: scale(1.05);
}
  
.cards__item:hover .cards__item__img {
    transform: scale(1.1);
}

@media (max-width: 1028px) {
    .Projects {
        margin-left: 10%;
    }

    .workItems {
        display: flex;
        flex-direction: column;
    }

}