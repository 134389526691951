nav {
    height: 100vh;
    width: 17.5%;
    background-color: rgba(0, 0, 0, 0.704);
    position: relative;
}

nav > ul {
    display: flex;
    flex-direction: column;
    color: white;
}

nav > ul > li {
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: border-bottom-color 0.3s;
    border-bottom: 1px solid transparent;
}

nav > ul > li:hover {
    border-bottom-color: white;
}

.profileLink:hover {
    border-bottom-color: transparent;
    background-color: rgb(88, 79, 79);
}

.mobileMenuIcon {
    position: fixed; 
    right: 20px;
    top: 20px;
    z-index: 1001;
    display: none;
}

@media (max-width: 1028px) {
    nav {
        display: none;
        transform: translateX(-100%);
        transition: transform 0.3s ease-out;
        position: fixed;
        top: 0; 
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        z-index: 1000;
        padding-top: 14rem;
        display: flex;
        justify-content: center;
    }

    .mobileMenuIcon {
        display: block;
        color: white;
        position: absolute;
        right: 4rem;
        margin-top: 2rem;
        font-size: 40px;
        cursor: pointer;
    }

    nav.open {
        transform: translateX(0);
        display: block;
        background-color: black;
        display: flex;
        justify-content: center;
    }

    nav > ul {
        gap: 2.7rem;
    }
    
}

