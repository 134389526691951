.Start {
    position: absolute;
    margin-left: 24%;
    top: 0;
    margin-top: 7rem;
}

@media (max-width: 1028px) {
    .Start {
        margin-left: 10%;
    }

}