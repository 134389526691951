.Home {
    position: absolute;
    margin-left: 24%;
    top: 0;
    margin-top: 7rem;
    padding-bottom: 2rem;
}

.header {
    color: white;
}

.header > h1 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 58px;
}

.header > p {
    font-weight: bold;
}

.social {
    margin-top: 40px;
    font-style: italic;
    font-family:'Courier New', Courier, monospace;
}

.socialLinks {
    margin-top: 10px;
    display: flex;
    gap: 14px;
}

.infoBox {
    color: white;
    margin-top: 2.4rem;
}

.infoBox > h2 {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.infoBox > h4 {
    margin-bottom: 10px;
}

.infoBox > h2 > span {
    display: flex;
    align-items: center;
}

.infoBox > form {
    padding: 1.7rem;
}

.infoBox > form > input {
    width: 40%;
    padding: 0.17rem;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
}

.infoBox > form > h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.infoBox > form > h3 > span {
    display: flex;
    align-items: center;
}

.infoBox > form > h3 > button {
    padding: 0.34rem;
    border-radius: 7px;
    cursor: pointer;
    border: none;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
    transition: box-shadow 0.3s;
}

.infoBox > form > h3 > button:hover {
    box-shadow: inset 1px 1px 1px 1px white;
}

.infoBox > form > textarea {
    width: 70%;
    height: 85px;
    background-color: rgba(0, 0, 0, 0.704);
    color: white;
    padding: 0.24rem;
    resize: none;
}

@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotateIcon {
    display: inline-block;
    animation: rotate360 5s linear infinite;
}

@media (max-width: 1028px) {
    .Home {
        margin-left: 10%;
    }

    .infoBox > form {
        width: 87%;
    }

    .header > h1 {
        font-size: 40px;
    }
}
