.About {
    position: absolute;
    margin-left: 24%;
    top: 0;
    margin-top: 7rem;
    margin-right: 24%;
}

.missionUl {
    list-style-type: none;
    color: white;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 10px;
}
  
.missionLi {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    font-size: larger;
    display: flex;
    flex-direction: column;
    gap: 14px;
    cursor: pointer;
}

.missionLi.show {
    opacity: 1;
    transform: translateY(0);
}

.collapseContent {
    max-height: 0;
    overflow: auto;
    transition: max-height 0.5s ease-out;
    background-color: rgba(0, 0, 0, 0.704);
    display: flex;
    justify-content: space-around;
}
  
.collapseContent.show {
    max-height: 170px;
    padding: 1rem;
}

.itemDiv {
    display: flex;
    align-items: center;
    gap: 14px;
    overflow:visible;
}

.collapseContent::-webkit-scrollbar {
    width: 10px;
}

.collapseContent::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.collapseContent::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.testimonialCard {
    background: #77777751;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;  
    max-width: 200px;
    margin-top: 10px;
}

.testimonialContent {
    font-style: italic;
    font-size: 18px;
    margin-bottom: 10px;
}

.testimonialAuthor {
    text-align: right;
    font-size: 16px;
    color: grey;
}


@media (max-width: 1028px) {
    .About {
        margin-left: 10%;
    }

    .collapseContent.show {
        max-height: 1000px;
    }
    

    .collapseContent {
        width: 108%;
        display: flex;
        flex-direction: column;
    }
}


  
  

